import React from 'react';
import ProjectContainer from '../../containers/projects/ProjectContainer';

const ProjectsPage = () => {
  return (
    <>
      <ProjectContainer />
    </>
  );
};

export default ProjectsPage;
