import React from 'react';
import ResumeContainer from '../../containers/resume/ResumeContainer';

function ResumePage() {
  return (
    <>
      <ResumeContainer />
    </>
  );
}

export default ResumePage;
